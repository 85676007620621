import * as React from "react"
import Layout from "../components/Base/Layout"
// import { Symbol } from "../components/Addons/Symbol"
import { Box, Heading, Stack, Text, Button, Link, Image } from "@chakra-ui/react"

// markup
const NotFoundPage = () => {
  return (
    <Layout> 
      <Box as="section" minHeight="100vh" position="relative" bg="base.50" py="28" px="28">
      <Image position="absolute" right="0" bottom="0" src="/pattern1.svg"/>
        <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '3rem', lg: '2rem' }}
            mt="8"
            align={{ lg: 'center' }}
            justify="space-between"
          >
            <Box flex="1" maxW={{ lg: '664px' }}>
              <Heading as="h1" size="4xl" color="gray.800" fontWeight="bold">
                404 Error
              </Heading>
              <Text fontSize="3xl" fontWeight="medium" > This page doesn't exist. </Text>
                <Button bg="blue.600" _hover={{bg: "blue.500"}} variant="solid" color="white" mt="4"> <Link href="/" textDecoration="none !important" > Back to home page</Link> </Button>
          </Box>
          </Stack>
      </Box>
    </Layout>
  )
}

export default NotFoundPage